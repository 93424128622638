// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import * as Tealium from '@/modules/tealium/Tealium'
import '@usb-shield/react-table/dist/library/styles/index.css'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import styles from './custom404.module.scss'
import USBLink from '@usb-shield/react-link'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'
import Head from 'next/head'
import { useRouter } from 'next/router'

const centerColOption = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 4,
    small: 2,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'bottom',
  justify: 'center',
}

const sideColOption = {
  span: null,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 2,
    small: 1,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'bottom',
  justify: 'center',
}

const Elavon404 = () => {
  let status = useGetUserInfoClientSide().status,
    userInfo = useGetUserInfoClientSide().userInfo,
    env = useGetUserInfoClientSide().env,
    cdnScripts = Tealium.getCdnScripts({ env: env, analyticsEnabled: true })

  const [initialStatus, setInitialStatus] = useState(status)
  let interval = useRef(null)
  const router = useRouter()

  useEffect(() => {
    if (initialStatus !== 'complete') {
      interval.current = setInterval(() => {
        setInitialStatus(document.readyState)
      }, 1000)
    } else {
      let reportingParams = {
        env: env,
        userInfo: userInfo,
        status: status,
      }

      if (!router.isReady) return
      Tealium.report404(reportingParams, router)
    }

    return () => clearInterval(interval.current)
  }, [initialStatus, router.isReady])

  return (
    <>
      <Head>
        <title>404 Error | Developer Portal</title>
      </Head>
      <div className={styles.mainContainer} data-testid="elavon-404-not-found">
        <USBGrid padding="normal">
          <USBColumn layoutOpts={sideColOption}></USBColumn>

          <USBColumn layoutOpts={centerColOption}>
            <div>
              <div className={styles.header}>
                <h1>404 page not found</h1>
              </div>
              <div className={styles.shortText}>
                <p>The page you're looking for no longer exists.</p>
              </div>

              <div className={styles.pageLink}>
                <USBLink linkType="arrow" href={'/'}>
                  Take me home
                </USBLink>
              </div>
            </div>
          </USBColumn>

          <USBColumn layoutOpts={sideColOption}></USBColumn>
        </USBGrid>
        {cdnScripts}
      </div>
    </>
  )
}

export default Elavon404
